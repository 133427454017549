















































































import { Component, Vue } from 'vue-property-decorator';
import randomNum from '@/utils/randomNum.ts';
import { ElForm } from 'element-ui/types/form';
import AES from '@/utils/AES.ts';
import { getCode, userLogin } from '@/serve/login.ts';

interface LoginForm {
  username: string;
  password: string | number;
  code: string;
  randomStr: string | number;
  scope: string;
}
@Component({
  components: {},
  name: 'UserLogin',
})
export default class UserLogin extends Vue {
  public loginForm: LoginForm = {
    // username: 'admin',
    // password: '123456',
    username: '',
    password: '',
    code: '',
    randomStr: '',
    scope: 'server',
  };
  private tempPd = ''
  public checked = false;
  public code = {
    src: '/code',
    value: '',
    len: 4,
    type: 'image',
  };
  public loginRules = {
    username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '密码长度最少为6位', trigger: 'blur' },
    ],
    code: [
      { required: true, message: '请输入验证码', trigger: 'blur' },
      { min: 4, max: 4, message: '验证码长度为4位', trigger: 'blur' },
    ],
  };
  public passwordType = 'password';
  public created() {
    this.refreshCode();
  }
  public mounted() {
    //
  }
  //刷新获取验证码
  public async refreshCode() {
    this.loginForm.code = '';
    this.loginForm.randomStr = randomNum(this.code.len);
    const data = {
      randomStr: this.loginForm.randomStr,
    };
    this.code.src = window.URL.createObjectURL(await getCode(data));
  }
  //登录
  public handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async (valid) => {
      if (valid) {
        //将密码加密
        // delete this.loginForm.code
        // delete this.loginForm.randomStr
        this.tempPd = this.loginForm.password as string
        this.loginForm.password = AES.encrypt(this.loginForm.password);
        const res = await userLogin(this.loginForm);
        if (res.access_token) {
          this.$router.push('index');
          //储存Token信息
          sessionStorage.setItem('Token', JSON.stringify(res));
          sessionStorage.setItem('tenant_id', res.tenant_id || '');
          this.tempPd = ''
        } else {
          this.refreshCode();
          this.loginForm.password = this.tempPd
          // this.loginForm.password = '';
          // this.loginForm.username = '';
          // this.$message({
          //   message: res.msg,
          //   type: 'error',
          // });
        }
      }
    });
  }
}
