import request from '../utils/request'

interface Code {
  randomStr: string;
}
//获取验证码
export function getCode(data: Code): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'code',
    responseType: 'blob'
  }, '', true)
}

//登录
export function userLogin(data: any): Promise<any> {
  data['grant_type'] = 'password'
  return request({
    method: 'POST',
    data: data,
    type: 'params',
    url: 'auth/oauth/token',
  }, 'login')
}
