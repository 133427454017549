import CryptoJS from 'crypto-js';

export default {
  //加密
  encrypt(word: any) {
    const keyStr = 'HealthHappy,HaHa'; //判断是否存在ksy，不存在就用定义好的key
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const encrypted = CryptoJS.AES.encrypt(word, key, { iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding });
    return encrypted.toString();
  },
}