










import { Component, Vue } from 'vue-property-decorator';
import userLogin from './userLogin.vue';
import moment from 'moment';
@Component({
  components: { userLogin },
  name: 'Login',
})
export default class Login extends Vue {
  public val = '';
  private date = '';
  public created() {
    this.date = moment().format('YYYY');
    sessionStorage.clear();
    localStorage.clear();
  }
}
